import { getClientById } from './getClientById';
import { createClient } from './createClient';
import { getClients } from './getClients';
import { updateClient } from './updateClient';
import { deleteClient } from './deleteClient';
import { thirdParty } from '..';

export const api = thirdParty;

export { getClientById, createClient, getClients, updateClient, deleteClient };
