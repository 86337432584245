import { api } from '.';
import { CLIENT_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import socialAudienceEntityParser from '@/entities/socialAudience/SocialAudienceEntityParser';

/**
 *
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<{data:SocialAudienceClient[], meta:import('@/model/shared/ResponseBuilder').EndpointMeta>}
 */
export async function getClients(params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: undefined,
  });
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(socialAudienceEntityParser.parse(data), data.meta).build();
}
