import { api } from '.';
import { CLIENT_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import socialAudienceEntityParser from '@/entities/socialAudience/SocialAudienceEntityParser';

/**
 *
 * @param {string} clientId
 * @param params
 * @returns {Promise.<{data:SocialAudienceClient, meta:import('@/model/shared/ResponseBuilder').EndpointMeta>}
 */
export async function getClientById(clientId, params = new QueryParamsBuilder()) {
  params.addInclude(['wallet', 'currency', 'linkedUsers']);

  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
  });
  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(socialAudienceEntityParser.parse(data), data.meta).build();
}
