import { api } from '.';
import { CLIENT_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ThirdPartyEntityParser from '@/entities/ThirdParty/ThirdPartyEntityParser';

/**
 *
 * @param {string} clientId
 * @returns {Promise.<{data:ThirdPartyEntityParser[],meta:import('@/model/shared/ResponseBuilder').EndpointMeta}>}
 */
export async function getClientById(clientId) {
  const params = new QueryParamsBuilder();
  params.addInclude(['currency', 'linkedUsers']);

  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
  });

  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(ThirdPartyEntityParser.parse(data), data.meta).build();
}
